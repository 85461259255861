<template>
    <div class="main-container mt-4">
      <div class="main-container-background shadow-lg p-3 mb-5 rounded" :style="`background-color:${urlImage}`">
        <div class="main-container-logo">
          <div class="J-profile-logo-content" style="width: 120px; height: 120px">
            <div class="J-profile-logo">
              
              <img id="avatar" :src="university.photo ? university.photo.link : imageUrl" v-if="!response.loading" loading="" />
              <span v-else class="J-profile-nonimage">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="container-title-company">
          <h1 class="text-style">{{ university.nameUniversity }}</h1>
          <div class="container-information-secondary">
            <div>
              <h6 class="text-style-secondary">{{ university.academyDivision }}</h6>
            </div>
            &nbsp;
            <div>
              <h6 class="text-style-secondary">|</h6>
            </div>
            &nbsp;
            <div>
              <h6 class="text-style-secondary">{{ university.campus }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="tarjetas-contadores" v-if="university.status">
        <!-- nuevo diseño -->
        <div class="newCardCount card shadow-lg" v-if="university.universityBag">
          <div class="card" style="display: flex; flex-direction: column; justify-content: space-between">
            <!-- Imagen con enlace dinámico usando el slug o pathMatch si no existe el slug -->
            <a :href="university.slug ? `/${university.slug}/bolsa` : '/pathMatch(.*)*'" target="_blank">
              <img :src="'/images/bag.png'" alt="Bolsa Universitaria" style="width: 100%; height: auto" />
            </a>
          </div>
        </div>
        <div class="newCardCount card shadow-lg" v-if="university.universityStore">
          <div class="card" style="display: flex; flex-direction: column; justify-content: space-between">
            <a :href="university.linkStore ? university.linkStore : 'https://www.tienda.mundouniversitario.com/'" target="_blank">
              <img :src="'/images/store.png'" alt="Tienda Universitaria" style="width: 100%; height: auto" />
            </a>
          </div>
        </div>
  
        <div class="newCardCount card shadow-lg" v-if="university.educationalOffer">
          <div class="card" style="display: flex; flex-direction: column; justify-content: space-between">
            <a :href="university.linkOffer ? university.linkOffer : '/pathMatch(.*)*'" target="_blank">
              <img :src="'/images/offerts.png'" alt="Oferta Educativa" style="width: 100%; height: auto" />
            </a>
          </div>
        </div>
        <div class="newCardCount card shadow-lg" v-if="university.universityEvents">
          <div class="card" style="display: flex; flex-direction: column; justify-content: space-between">
            <a :href="university.linkEvents ? university.linkEvents : '/pathMatch(.*)*'" target="_blank">
              <img :src="'/images/events.png'" alt="Eventos" style="width: 100%; height: auto" />
            </a>
          </div>
        </div>
      </div>
      <div v-else>
      <div>
          <center><img :src="'/images/proximamente.jpg'" style="width: 45%; margin-top: 2%;" /></center>
          <center>
            <p class="text" style="font-family: Poppins; text-align: center;font-size: 25px;">
              ¡Disponible muy pronto!
            </p>
          </center>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { UserService } from "@/services";
  import universityService from "../services/university.service";
  
  export default {
    data: () => {
      return {
        response: {
          loading: false,
        },
        urlImage: "#84a7d0",
        imageUrl: "/images/profile.jpg",
        vacante: false,
        dataUser: {},
        university: {},
        idUniversity: "",
        request: {},
        companyFilter: "",
        universityBag: false,
        universityStore: false,
        educationalOffer: false,
        universityEvents: false,
      };
    },
    created() {
        this.search = this.$route.params.slug;
        console.log(this.search);
        this.getUniversityBySlug(this.search);
    },
    methods: {
      // async getUniversityBySlug(slug) {
      //   try {
      //       const resp = await universityService.getdataUniversityBySlug({ slug });
      //       const { data } = resp.data;
      //       this.university = data;
      //       console.log(this.university);
      //       this.universityBag = this.university.universityBag;
      //       this.universityStore = this.university.universityStore;
      //       this.educationalOffer = this.university.educationalOffer;
      //       this.universityEvents = this.university.universityEvents;
      //   } catch (error) {
      //     console.error("Error en la llamada a la API:", error.message);
      //   }        
      // },
      async getUniversityBySlug(slug) {
          try {
              const resp = await universityService.getdataUniversityBySlug({ slug });
              console.log(resp.data)
              const { data, photo } = resp.data;
              this.university = data;
              
              if (photo) {
                this.university.photo = photo; // Guarda la photo en la propiedad photo de university
              }
              console.log('Datos de la universidad:', this.university);
              if (this.university === null) {
                this.$router.push('/upcoming'); 
                return; 
              }
              this.universityBag = this.university.universityBag;
              this.universityStore = this.university.universityStore;
              this.educationalOffer = this.university.educationalOffer;
              this.universityEvents = this.university.universityEvents;
          } catch (error) {
              console.error("Error en la llamada a la API:", error.message);
              this.$router.push('/upcoming'); // También redirige en caso de error
          }        
      },
    },
  };
  </script>
  
  <style land="scss" scoped>
  @import "../styles/profilecompany.scss";
  </style>
  